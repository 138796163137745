import Button from '../Button/Button';
import './ResumeBanner.scss';

const ResumeBanner = ({ theme }) => {

    return (
        <section className='resume-banner'>
            <Button wiggle="true" className="dark" type="outline">download my resume</Button>
        </section>
    )
}

export default ResumeBanner;