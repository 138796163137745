import classNames from 'classnames';
import './Section.scss';

const Section = ({ children, className }) => {
    const sectionCls = classNames('section', className);
    return (
        <section className={sectionCls}>
            {children}
        </section>
    )
}

export default Section;