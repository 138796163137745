import './Button.scss';
import classNames from 'classnames';

const Button = ({children, className, type, wiggle}) => {
    const shouldWiggle = wiggle ? 'wiggle' : '';
    const buttonCls = classNames(`button__${type}`, shouldWiggle, className);

    return (
        <button className={buttonCls}>{children}</button>
    )
}

export default Button;