import Hero from "../../components/Hero/Hero";
import ResumeBanner from "../../components/ResumeBanner/ResumeBanner";
import Section from "../../components/Section/Section";
import './Home.scss';
import { Bounce } from "react-awesome-reveal";
import classNames from "classnames";

const Home = ({ theme }) => {
    const renderNotes = () => {
        const notes = ['fdsds', 'fdsf', 'fdsf', 'fdsf'];

        return (
            <div class="notes-wrapper">
                {
                    notes.map((note, index) => {
                        const noteCls = classNames('note', `note-${index + 1}`);
                        return (
                            <Bounce triggerOnce={true}>
                                <div className={noteCls}>
                                    <div className="note-text">
                                        {note}
                                    </div>
                                </div>
                            </Bounce>
                        )
                    })
                }
            </div>
        )
    }

    return (
        <>
            <div className="wrapper">
                <Hero />
            </div>
            <ResumeBanner theme={theme} />
            <div className="wrapper">
                <Section className="section-home">
                    <h3>Delivery as an art, and science.</h3>
                    <Bounce triggerOnce={true}>
                        <p className="vert-offset-24">Experienced delivery leader with a track record of building strong, empowered teams that make a meaningful impact through Agile principles, design thinking, and data. I believe transparency, collaboration, and trust make for quality products, happy customers, and strong businesses.</p>
                        <p>Always happy to connect!</p>
                    </Bounce>
                </Section>
                <Section className="section-home">
                    <h3>Principles</h3>
                    {renderNotes()}
                </Section>
            </div>
        </>
    )
}

export default Home;