import './Hero.scss';
import headshotImg from '../../assets/img/headshot.jpeg';
import { Bounce } from 'react-awesome-reveal';

const Hero = () => {
    const renderUnderline = () => {
        return (
            <u class="underline-animation">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 255 32.61"><path d="M5 14.11s54-8 125-9 120 5 120 5-200.5-5.5-239.5 17.5" class="stroke" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="10" /></svg>
                simple.</u>
        )
    }
    return (
        <section className='hero'>
            <Bounce left triggerOnce={true}><img alt="sydney schramm" className='headshot' src={headshotImg} /></Bounce>
            <span className='hero__stuff'>
                <Bounce right triggerOnce={true}><h2 className='hero__text'>I make program management {renderUnderline()}</h2></Bounce>
                <Bounce right triggerOnce={true}><p className='hero__para'>I bring a practical approach to leading product development and engineering teams, guided by a proven understanding of systems, technology, and business. From successfully managing several 100+ person projects to conducting engaging and outcome-focused workshops, I strive to create environments where ideas flourish and innovation thrives.</p></Bounce>
            </span>
        </section>
    )
}

export default Hero;