import { useState } from 'react';
import './styles/styles.scss'
import Home from './pages/Home/Home';
import Header from './pages/Header/Header';
import classNames from 'classnames';

function App() {
  const [isLightTheme, setTheme] = useState(true);
  const appCls = classNames('app', isLightTheme ? 'light' : 'dark');

  const renderToggle = () => {
    return (
      <span className='toggle'>
        <input type="checkbox" id="toggle_checkbox" />
        <label for="toggle_checkbox" onClick={() => setTheme(!isLightTheme)}></label>
      </span>
    )
  }

  return (
    <div className={appCls}>
      {renderToggle()}
      <Header />
      <Home theme={isLightTheme ? 'dark' : 'light'} />
    </div>
  );
}

export default App;
