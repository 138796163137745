import Button from '../../components/Button/Button';
import './Header.scss';

const Header = () => {

    return (
        <header className='header'>
            <h2>syd schramm</h2>
            <div className='button-group'>
                <Button type="outline">about</Button>
                <Button type="outline">gallery</Button>
                <Button type="outline">projects</Button>
                <Button type="outline">contact</Button>
            </div>
        </header>
    )
}

export default Header;